export const pStudio = {
  account: {
    read: 's.account.read',
    write: 's.account.write'
  },

  academicYear: {
    read: 's.academicYear.read'
  },

  academicProgram: {
    read: 's.academicProgram.read',
    write: 's.academicProgram.write',
    delete: 's.academicProgram.delete',
    shortCourseRead: 's.academicProgram.shortCourseRead',
    shortCourseWrite: 's.academicProgram.shortCourseWrite',
    shortCourseDelete: 's.academicProgram.shortCourseDelete',
    s15Read: 's.academicProgram.s15Read',
    s15Write: 's.academicProgram.s15Write',
    s15Delete: 's.academicProgram.s15Delete'
  },

  announcement: {
    read: 's.announcement.read',
    write: 's.announcement.write',
    delete: 's.announcement.delete'
  },

  attendanceList: {
    read: 's.attendanceList.read',
    write: 's.attendanceList.write',
    delete: 's.attendanceList.delete'
  },

  attendanceStaff: {
    read: 's.attendanceStaff.read',
    write: 's.attendanceStaff.write',
    delete: 's.attendanceStaff.delete'
  },

  building: {
    read: 's.building.read',
    write: 's.building.write',
    delete: 's.building.delete'
  },

  calendar: {
    read: 's.calendar.read',
    write: 's.calendar.write',
    delete: 's.calendar.delete'
  },

  classEnrolment: {
    read: 's.classEnrolment.read',
    write: 's.classEnrolment.write',
    delete: 's.classEnrolment.delete',
    changeClass: 's.classEnrolment.changeClass',
    deleteName: 's.classEnrolment.deleteName'
  },

  classFileShare: {
    read: 's.classFileShare.read',
    write: 's.classFileShare.write',
    delete: 's.classFileShare.delete'
  },

  classes: {
    read: 's.class.read',
    write: 's.class.write',
    delete: 's.class.delete'
  },

  promoteStudent: {
    read: 's.promoteStudent.read',
    write: 's.promoteStudent.write'
  },

  classSubject: {
    read: 's.classSubject.read',
    write: 's.classSubject.write',
    delete: 's.classSubject.delete'
  },

  dashboard: {
    gender: 's.dashboard.gender',
    mainCount: 's.dashboard.mainCount',
    studentAttend: 's.dashboard.studentAttend',
    staffAttend: 's.dashboard.staffAttend',
    requiredInput: 's.dashboard.requiredInput'
  },

  degree: {
    read: 's.degree.read'
  },

  department: {
    read: 's.department.read',
    write: 's.department.write',
    delete: 's.department.delete'
  },
  developmentPartner: {
    read: 's.developmentPartner.read',
    write: 's.developmentPartner.write',
    delete: 's.developmentPartner.deleteAny'
  },

  developmentPlan: {
    read: 's.developmentPlan.read',
    write: 's.developmentPlan.write',
    delete: 's.developmentPlan.deleteAny'
  },

  faculty: {
    read: 's.faculty.read',
    write: 's.faculty.write',
    delete: 's.faculty.delete'
  },

  feedback: {
    read: 's.feedback.read',
    write: 's.feedback.write',
    delete: 's.feedback.delete',
    adminRead: 's.feedback.adminRead'
  },

  generation: {
    read: 's.generation.read',
    write: 's.generation.write',
    delete: 's.generation.delete'
  },

  inventoryCategory: {
    read: 's.inventoryCategory.read',
    write: 's.inventoryCategory.write',
    delete: 's.inventoryCategory.delete'
  },

  inventoryItem: {
    read: 's.inventoryItem.read',
    write: 's.inventoryItem.write',
    delete: 's.inventoryItem.delete'
  },

  leaveType: {
    read: 's.leaveType.read'
  },
  logSchool: {
    read: 's.logSchool.read'
  },
  logUser: {
    read: 's.logUser.read'
  },

  program: {
    read: 's.program.read',
    write: 's.program.write',
    delete: 's.program.delete'
  },

  personal: {
    readSchedule: 's.personal.readSchedule',
    readAttendance: 's.personal.readAttendance'
  },

  staffRequestPermission: {
    read: 's.staffRequestPermission.read',
    write: 's.staffRequestPermission.write',
    delete: 's.staffRequestPermission.delete',
    approval: 's.staffRequestPermission.approval',
    adminRead: 's.staffRequestPermission.adminRead'
  },

  permissionRequest: {
    read: 's.permissionRequest.read',
    write: 's.permissionRequest.write'
  },

  paymentList: {
    read: 's.payment.read',
    write: 's.payment.write',
    delete: 's.payment.delete'
  },
  paymentTemplate: {
    read: 's.paymentTemplate.read',
    write: 's.paymentTemplate.write',
    delete: 's.paymentTemplate.delete'
  },

  report: {
    gender: 's.report.gender',
    longCourseBySkill: 's.report.longCourseBySkill',
    shortCourseBySkill: 's.report.shortCourseBySkill',
    graduatedStudent: 's.report.graduatedStudent',
    longCoursePassFailed: 's.report.longCoursePassFailed',
    developmentPartnerData: 's.report.developmentPartnerData',
    longCourseBySchool: 's.report.longCourseBySchool',
    shortCourseBySchool: 's.report.shortCourseBySchool',
    studentBySkill: 's.report.studentBySkill',
    studentBySchool: 's.report.studentBySchool'
  },

  room: {
    read: 's.room.read',
    write: 's.room.write',
    delete: 's.room.delete'
  },

  school: {
    read: 's.school.read',
    write: 's.school.write'
  },

  score: {
    write: 's.score.write',
    read: 's.score.read',
    readImport: 's.score.readImport',
    writeImport: 's.score.writeImport'
  },

  shift: {
    read: 's.shift.read',
    write: 's.shift.write',
    delete: 's.shift.delete'
  },
  skill: {
    read: 's.skill.read'
  },
  staff: {
    read: 's.staff.read',
    write: 's.staff.write',
    resetPwd: 's.staff.resetPwd',
    setActive: 's.staff.setActive'
  },
  student: {
    write: 's.student.write',
    resetPwd: 's.student.resetPwd',
    setActive: 's.student.setActive',
    read: 's.student.read',
    graduated: 's.student.graduated',
    delete: 's.student.delete'
  },
  studentOccupation: {
    write: 's.studentOccupation.write',
    read: 's.studentOccupation.read',
    delete: 's.studentOccupation.delete'
  },
  fileImport: {
    write: 's.fileImport.write',
    read: 's.fileImport.read',
    writeStaff: 's.fileImport.writeStaff',
    readStaff: 's.fileImport.readStaff'
  },
  subject: {
    read: 's.subject.read',
    write: 's.subject.write',
    delete: 's.subject.delete'
  },
  scheduleList: {
    read: 's.scheduleList.read',
    write: 's.scheduleList.write',
    delete: 's.scheduleList.delete'
  },
  scoreType: {
    read: 's.scoreType.read',
    write: 's.scoreType.write',
    delete: 's.scoreType.delete'
  },
  gradeScale: {
    read: 's.gradeScale.read',
    write: 's.gradeScale.write'
  },
  classDataApproval: {
    approve: 's.classDataApproval.approve',
    request: 's.classDataApproval.request',
    approvePromote: 's.classDataApproval.approvePromote',
    requestPromote: 's.classDataApproval.requestPromote'
  },
  scorePassFail: {
    read: 's.scorePassFail.read',
    write: 's.scorePassFail.write',
    delete: 's.scorePassFail.delete'
  },
  s15Course: {
    read: 's.s15Course.read',
    write: 's.s15Course.write',
    delete: 's.s15Course.delete'
  },
  attendanceSubmit: {
    read: 's.attendanceSubmit.read',
    write: 's.attendanceSubmit.write',
    delete: 's.attendanceSubmit.delete'
  },

  scholarshipPayment: {
    read: 's.scholarshipPayment.read'
  },

  requestCertificate: {
    read: 's.requestCertificate.read',
    write: 's.requestCertificate.write',
    readLongCourse: 's.requestCertificate.readLongCourse',
    writeLongCourse: 's.requestCertificate.writeLongCourse'
  },

  studentIntership: {
    write: 's.studentIntership.write',
    read: 's.studentIntership.read',
    delete: 's.studentIntership.delete' 
  },
  studentEnrolRequest: {
    write: 's.studentEnrolRequest.write',
    read: 's.studentEnrolRequest.read'
  },
  studentTranscript: {
    write: 's.studentTranscript.write',
    read: 's.studentTranscript.read',
    delete: 's.studentTranscript.delete'
  },
  reportS15: {
    studentList: 's.reportS15.studentList',
    adminDataStudentApply: 's.reportS15.adminDataStudentApply',
    adminDataApprovedCount: 's.reportS15.adminDataApprovedCount'
  },
  studentCertificate: {
    read: 's.studentCertificate.read',
    reachCandidate: 's.studentCertificate.reachCandidate',
    print: 's.studentCertificate.print'
  }
};
